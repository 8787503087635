@use '/src/assets/new_variables.scss' as *;

.navbar-v2-dynamic-element {
  display: block;
  width: 100%;

  @media screen and (max-width: 1199px) {
    padding: 0;
  }
  padding: 30px 35px;
  &.with-additional-area,
  &.with-additional-area-extra {
    display: flex;
    flex-wrap: wrap;
  }

  .additional-area-extra {
    width: 21%;
    border-left: 2px $color-link-water solid;

    @media screen and (max-width: 1199px) {
      width: 100%;
      border-left: unset;
      border-right: unset;
    }

    .extra-title {
      color: $color-primary;
      margin: 0 25px;
      font-weight: 700;
      font-size: 26px;
      margin-bottom: 30px;

      @media screen and (max-width: 1199px) {
        margin: 0 0px 15px;
      }
    }

    .grid-items {
      grid-template-columns: repeat(1, 1fr);

      @media screen and (max-width: 1199px) {
        width: 100%;
      }
    }
  }

  .grid-items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(70px, 70px);
    &.with-additional-area {
      width: 65%;
      grid-template-columns: repeat(3, 1fr);

      @media screen and (min-width: 992px) and (max-width: 1600px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 1199px) {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
      }
    }

    &.with-additional-area-extra {
      width: 44%;
      grid-template-columns: repeat(2, 1fr);

      @media screen and (max-width: 1199px) {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
      }
    }

    &.with-description {
      grid-auto-rows: minmax(100px, 100px);
    }

    @media screen and (max-width: 1199px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(10px, auto);
      margin-bottom: 20px;
    }

    .item {
      margin: 0px 25px;
      @media screen and (max-width: 1199px) {
        border-bottom: solid;
        border-color: $color-link-water;
        border-width: 1px;
        padding-bottom: 10px;
        margin: unset;
      }

      .title {
        font-weight: 600;
        color: $color-primary;
        font-family: $font-secondry;
        font-size: 20px;
        text-wrap: nowrap;
        &::before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 7.5px;
          background-color: #ff9e1b;
          margin-right: 10px;
        }
      }

      .description {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        font-family: $font-secondry;
        padding: 0px 20px;
        text-wrap: nowrap;
      }
      &:last-of-type {
        border: unset;
      }
    }
  }
  .additional-area {
    width: 35%;
    text-align: right;
    @media screen and (max-width: 1199px) {
      width: 100%;
      margin-bottom: 10px;
    }

    .banner-image {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      max-width: 400px;
      max-height: 400px;
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    .banner-image-mobile {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      max-width: 350px;
      max-height: 200px;
      float: left;
      @media screen and (min-width: 992px) {
        display: none;
      }
    }
  }

  .list-link {
    margin-top: 30px;
    color: $color-primary;
    font-weight: 600;
    width: 100%;
    margin: 0px 25px;
    font-size: 20px;
    .fa-arrow-right {
      margin-left: 5px;
    }
    @media screen and (max-width: 1199px) {
      margin: unset;
    }
    &.desktop {
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    &.mobile {
      @media screen and (min-width: 992px) {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
  }
}
