@use '/src/assets/new_variables.scss' as *;

.navbar-country-language {
  padding: 2rem 1rem 2rem 1rem;
  width: 100%;

  label {
    padding-bottom: 2px;
    color: $color-hoki;
    font-weight: 400;
  }
  .country {
    margin-bottom: 1rem;

    .country-autocomplete {
      .rbt-input {
        font-weight: bold;
        color: $color-primary;

        &::placeholder {
          color: $color-primary;
        }
      }
    }
  }
  .language {
    .language-dropdown {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.5;
      color: $color-primary;
      background-color: $color-white;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.375rem;

      option[disabled] {
        display: none;
      }
    }
  }
  .btn {
    border: 1px solid transparent;
    border-radius: 10px;
    font-size: 18px;
    line-height: 19px;
    margin-top: 1rem;
    padding: 12px;
    position: relative;
    transition: all 0.6s ease-out;
    width: 100%;
    color: $color-white;
    background-color: $color-primary;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
    }
  }
}
