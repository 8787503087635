@use '/src/assets/new_variables.scss' as *;
.navbar-v2 {
  &-main {
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    flex: 1;
    height: 100%;
    &-item {
      align-items: center;
      display: flex;
      cursor: pointer;
      &:hover {
        box-shadow: 0 5px 0 0 white;
      }
      @media screen and (max-width: 1199px) {
        flex-direction: column;

        .no-toggle {
          align-self: self-start;
          margin-bottom: 10px;
          padding-bottom: 5px;
        }

        &:hover {
          box-shadow: unset;
        }
      }
      &.open {
        @media screen and (max-width: 1199px) {
          box-shadow: unset;
          flex-direction: column;
        }
      }

      &.no-toggle {
        &:hover {
          box-shadow: 0 5px 0 0 white;
          @media screen and (max-width: 1199px) {
            box-shadow: unset;
          }
        }
      }
      &-not-root {
        &:hover {
          box-shadow: 0 5px 0 0 $color-primary !important;
        }
      }
    }
    @media screen and (min-width: 1200px) {
      align-items: center;
    }
    & .navbar-v2-toggler {
      @media screen and (max-width: 1199px) {
        margin-bottom: 10px;
        padding-bottom: 5px;

        &-open-enter-done {
          font-weight: 700;
          border-bottom: 3px solid $color-primary;
        }
      }
    }
    &-link {
      color: #ffffff;
      text-decoration: none;
      padding-right: 5px;
      font-size: 22px;
      display: flex;
      font-family: 'Assistant';
      text-decoration: none;
      font-weight: 400;

      @media screen and (min-width: 992px) {
        width: 100%;
      }
      @media screen and (max-width: 1199px) {
        color: $color-primary;
      }

      @media screen and (min-width: 991px) and (max-width: 1599px) {
        font-size: 20px;
      }

      &:hover {
        color: #ffffff;
        @media screen and (max-width: 1199px) {
          color: $color-primary;
        }
      }

      &-not-root {
        color: $color-primary;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}
