@use '/src/assets/new_variables.scss' as *;

//this is temp for test3
.wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  -webkit-transition: -webkit-filter 0.3s;
  transition: filter 0.3s;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
}

body {
  &.mobile-open {
    overflow: hidden;
  }
}
//end test3 temp
.navbar-v2 {
  color: #ffffff;
  font-family: $font-secondry2;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  height: 100%;
  background-color: transparent;
  transition: all 300ms ease;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 991px) and (max-width: 1399px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media print {
    display: none;
  }
  &-top {
    background: linear-gradient(to bottom, rgba(0, 45, 114, 1) 50%, rgba(0, 0, 0, 0) 100%);
  }
  &-scrolled {
    background-color: $color-primary;
  }

  &-top {
    &-not-root {
      background: #ffffff;
      background-color: #ffffff;

      &.navbar-v2-scrolled {
        -webkit-box-shadow: 0px 4px 5px -4px rgba(0, 0, 0, 0.58);
        -moz-box-shadow: 0px 4px 5px -4px rgba(0, 0, 0, 0.58);
        box-shadow: 0px 4px 5px -4px rgba(0, 0, 0, 0.58);
      }
    }
  }
  @media screen and (max-width: 1199px) {
    color: $color-primary;
    padding-left: 5px;
    padding-right: 5px;
  }
  &-menu-block {
    @media screen and (max-width: 991px) {
      top: 100%;
      max-height: calc(100dvh - 100px);
      overflow: auto;
    }
  }

  &-logo {
    margin-right: 10px;
    height: 100%;
    width: auto;

    @media screen and (max-width: 1599px) {
      display: none;
    }

    @media screen and (max-width: 1199px) {
      display: block;
    }

    @media screen and (max-width: 425px) {
      display: none;
    }

    &-tablet {
      display: none;
      @media screen and (min-width: 1200px) and (max-width: 1599px) {
        display: flex;
        margin-bottom: 1rem;
        margin-top: 1rem;
        padding-right: 1rem;
      }

      @media screen and (max-width: 425px) {
        display: flex;
        margin-bottom: 1rem;
        margin-top: 1rem;
        padding-right: 1rem;
      }

      a {
        img {
          max-width: 400px;
          max-height: 160px;

          @media screen and (max-width: 425px) {
            width: 5rem;
          }
        }
      }
    }
    a {
      img {
        max-width: 400px;
        max-height: 160px;
      }
    }
  }

  &-sections {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .mobile-menu {
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}
