@use '/src/assets/new_variables.scss' as *;

.navbar-v2-user-control {
  position: relative;
  display: flex;

  .user-active {
    background-color: green;
    border: 2px solid #ffffff;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: block;
    align-self: center;
    margin-left: 3px;
  }

  &-img {
    &-non-root {
      filter: brightness(0) saturate(100%) invert(9%) sepia(88%) saturate(3369%) hue-rotate(209deg)
        brightness(99%) contrast(104%);
    }
  }

  &-name {
    margin-left: 10px;
    &-non-root {
      color: $color-primary;
    }
  }
}
