@use '/src/assets/new_variables.scss' as *;
.navbar-v2-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;

  &-open {
    &-enter {
      opacity: 0;
      transform: scale(0.9);
    }
    &-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity $menu-animation-duration, transform $menu-animation-duration;
    }
    &-enter-done {
      opacity: 1;
    }

    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity $menu-animation-duration, transform $menu-animation-duration;
    }
    &-exit-done {
      opacity: 0;
    }
  }
  &-wrapper {
    &-main {
      display: flex;
      width: 100%;
      flex-direction: column;

      .go-back {
        font-weight: 600;
        font-size: 16px;
        color: $color-golden-bell;

        .fa-arrow-left {
          margin-right: 10px;
        }
      }
    }
    &-main,
    &-controll {
      padding: 0 20px;
    }
  }
  .navbar-v2-toggler-chevron {
    @media screen and (max-width: 1199px) {
      color: $color-primary;
    }
  }
  &-main-section {
    border-bottom: 1px $color-link-water solid;
    & .navbar-v2-main-link {
      font-size: 20px;
      font-family: $font-secondry;
      font-weight: 500;
    }
  }
  &-main-section,
  &-additional-section {
    flex-direction: column;
    width: 100%;
  }
  &-additional-section {
    gap: 20px;

    & .navbar-v2-additional-link {
      color: #000000;
      font-size: 18px;
      font-family: $font-secondry;
      font-weight: 500;
    }
  }
  &-controls {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $color-link-water;
    padding-bottom: 1.5rem;

    @media screen and (min-width: 992px) and (max-width: 1199px) {
      display: none;
    }
  }

  &-search {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
