@use '/src/assets/new_variables.scss' as *;

.navbar-v2-burger {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 22px;
  gap: 5px;
  transition: all $menu-animation-duration ease;
  margin-right: 25px;
  margin-left: 20px;
  &-line {
    display: block;
    height: 3px;
    background-color: #ffffff;
    width: 22px;
    border-radius: 999999;

    transition: all $menu-animation-duration ease;

    &-not-root {
      background-color: $color-primary;
    }
  }

  &.burger-open {
    margin-top: 4px;
    .burger-line {
      &-top,
      &-middle {
        margin: -4px 0;
      }
      &-top {
        transform: rotate(45deg);
      }
      &-middle {
        transform: rotate(-45deg);
      }
      &-bottom {
        width: 0;
      }
    }
  }
}
