@use '/src/assets/new_variables.scss' as *;

.navbar-v2-toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  &-chevron {
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
    height: 15px;

    &-not-root {
      color: #ffffff;
    }

    &-dynamic-not-root {
      color: $color-primary;
    }
  }

  &-open {
    .navbar-v2-toggler-chevron {
      transform: rotate(180deg);
    }
  }
}
