@use '/src/assets/new_variables.scss' as *;

.navbar-v2-country-language {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ffffff;

  @media screen and (max-width: 991px) {
    color: $color-primary;
  }
  &:hover {
    box-shadow: 0px 4px 0px 0px $color-white;
  }
  cursor: pointer;
  .navbar-v2 {
    &-language {
      text-transform: uppercase;
      font-size: 22px;

      &-not-root {
        color: $color-primary;
      }
    }
    &-country,
    &-country-wrapper {
      height: 20px;
      width: 33px;
    }
    &-country-wrapper {
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ffffff;
      img {
        height: 20px;
        width: 34px;
      }
      @media screen and (max-width: 991px) {
        border: 1px solid $color-primary;
      }

      &-not-root {
        border: 1px solid $color-primary;
      }
    }
  }
}
