@use '/src/assets/new_variables.scss' as *;

.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;

    .left-arrow,
    .right-arrow {
      top: 36px;
      width: 40px;
      height: 40px;
      font-size: 0;
      line-height: 0;
      position: absolute;
      padding: 0;
      transform: translate(0, -50%);
      opacity: 1;
      background: unset;
      z-index: 99999;
      border: unset;
      &:disabled {
        opacity: 0.25;
      }
      &:before {
        color: $color-primary;
        font-size: 40px;
        font-family: 'slick';
        line-height: 1;
        opacity: 0.75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .left-arrow {
      left: 0;
      @media screen and (max-width: 1440px) {
        left: -35px;
      }
      &:before {
        content: '←';
      }
    }

    .right-arrow {
      right: 0;
      @media screen and (max-width: 1440px) {
        right: -35px;
      }
      &:before {
        content: '→';
      }
    }

    .carousel-content-wrapper {
      overflow: hidden;
      width: 100%;
      height: 100%;

      .carousel-content {
        display: flex;
        transition: all 250ms linear;
        -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
        scrollbar-width: none; /* hide scrollbar in Firefox */

        & > * {
          width: 100%;
          flex-shrink: 0;
          flex-grow: 1;
        }

        &.show- {
          &2 > * {
            width: 50%;
          }
          &3 > * {
            width: calc(100% / 3);
          }
          &4 > * {
            width: calc(100% / 4);
          }
          &5 > * {
            width: calc(100% / 5);
          }
          &6 > * {
            width: calc(100% / 6);
          }
          &7 > * {
            width: calc(100% / 7);
          }
          &8 > * {
            width: calc(100% / 8);
          }
          &9 > * {
            width: calc(100% / 9);
          }
          &10 > * {
            width: calc(100% / 10);
          }
        }
      }
    }
  }
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}
