@use '/src/assets/new_variables.scss' as *;
.navbar-v2 {
  &-additional {
    display: flex;
    gap: 30px;
    justify-content: end;
    border-bottom: 2px solid rgb(255, 255, 255, 0.3);
    padding: 10px 0;
    font-family: $font-secondry;
    position: relative;

    & .navbar-v2-dropblock-content {
      align-items: start;
      border-radius: 0 0 20px 20px;
      @media screen and (max-width: 1279px) {
        padding: 10px 0;
      }
    }
    & .navbar-v2-toggler-chevron {
      top: 50%;

      @media screen and (min-width: 992px) {
        right: -30px;
      }
    }
    & .dropdown-menu {
      z-index: 6000;
      padding: 10px 20px;
    }
    &.sub-menu {
      flex-direction: column;
      & .navbar-v2-additional-link {
        color: $color-primary;
      }
    }
    &-toggle {
      background: transparent !important;
      border: none;
      color: $color-primary;
      padding: 0;
      text-align: right;
      &::after {
        display: none;
      }
    }
    &-link {
      color: #ffffff;
      text-decoration: none;
      font-size: 18px;
      font-weight: 300;

      &:hover {
        box-shadow: 0 5px 0 0 #ffffff;
        color: #ffffff;
      }

      &.inner {
        color: $color-primary;
        height: 60px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        width: 100%;
        &:not(:first-child) {
          border-top: 2px solid #d8e3f2;
        }
        &:hover {
          text-decoration: none;
          color: $color-primary;
        }
      }

      &-not-root {
        color: $color-primary;

        &:hover {
          color: $color-primary;
        }
      }
    }

    &-not-root {
      border-bottom: 2px solid rgb(216, 227, 242, 0.3);
    }
  }
}
