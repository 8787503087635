@use '/src/assets/new_variables.scss' as *;
.navbar-v2 {
  &-core {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 0;

    &-item {
      height: 90px;

      @media screen and (max-width: 1199px) {
        display: none;
      }
    }
    &-ineed {
      padding: 0 5px 0 20px;
      height: 41px;
      background-color: $color-white;
      margin-right: 20px;
      font-size: 24px;
      border-radius: 10px;
      text-wrap: nowrap;
      border-width: 0px;
      min-width: 132px;
      width: 100%;

      @media screen and (min-width: 991px) and (max-width: 1599px) {
        font-size: 22px;
        min-width: 100px;
      }
      &-wrapper {
        display: flex;
        align-items: center;

        &.navbar-v2-core-item {
          &.open {
            &::before {
              content: '';
              border-bottom: 5px solid #ffffff;
              position: absolute;
              bottom: 20px;
              width: 132px;

              @media screen and (max-width: 1399px) {
                width: 102px;
              }
            }
          }
        }
      }
      @media screen and (max-width: 991px) {
        font-size: 18px;
        height: 32px;
        border-radius: 5px;
        margin-right: 0px;
        padding: 0 12px 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        min-width: 101px;
        width: 100%;
      }

      @media screen and (max-width: 425px) {
        padding: 0 6px;
        min-width: 80px;
      }

      &-toggler {
        color: $color-primary;
        box-shadow: none;
        justify-content: center;
        font-family: $font-primary;
        &-not-root {
          color: #ffffff;

          &:hover,
          &:focus {
            background-color: $color-primary;
          }
        }
      }
      &:hover,
      &:focus {
        background-color: #ffffff;
        color: $color-primary;
      }

      &-not-root {
        background-color: $color-primary;

        &:hover,
        &:focus {
          background-color: $color-primary;
        }
      }
    }

    &-controlls {
      display: flex;
      list-style: none;
      align-items: center;
      margin-bottom: 0;
      gap: 30px;

      @media screen and (min-width: 992px) and (max-width: 1399px) {
        padding-left: 1rem;
      }

      @media screen and (max-width: 991px) {
        margin-right: 1rem;
      }

      @media screen and (max-width: 435px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 1rem;
        margin-right: 1rem;
      }

      @media screen and (min-width: 1200px) and (max-width: 1249px) {
        gap: 10px;
      }
    }

    &-control {
      margin-right: 20px;
      display: flex;
      align-items: center;
      height: 100%;
      .navbar-v2-user-control {
        img {
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      &:last-child {
        @media screen and (max-width: 991px) {
          margin-right: 10px;
        }
      }

      @media screen and (min-width: 991px) and (max-width: 1399px) {
        margin-right: unset;
      }

      &-user {
        width: 100%;
        max-width: 480px;

        @media screen and (min-width: 991px) {
          border-radius: 0 0 20px 20px;
        }
      }
      &.open {
        @media screen and (max-width: 991px) {
          box-shadow: 0 4px 0 0 $color-primary;
        }
      }

      &.user {
        margin-right: 0;
      }

      &.search-button {
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    }
    .language-country-button {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    &-content-countryLanguage {
      @media screen and (min-width: 991px) {
        border-radius: 0 0 20px 20px;
      }
    }
  }
}
