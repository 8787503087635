@use '/src/assets/new_variables.scss' as *;

.navbar-v2-user {
  &-login {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    color: $color-primary;

    &-option {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 10px;

      & .login-description {
        font-family: $font-secondry;
        font-size: 26px;
        color: $color-primary-dark;
        padding-bottom: 40px;
        width: 300px;
        text-align: center;
      }

      & .forgot-password {
        color: $color-primary;
      }

      & .register {
        color: $color-primary;
      }
      & .user {
        &-login {
          button {
            width: 300px;
            height: 60px;
            font-size: 20px;
            font-family: $font-secondry;
            border-radius: 10px;
          }
        }

        &-logout {
          font-family: $font-secondry;
          font-size: 20px;
        }
      }
    }
    & header {
      font-size: 26px;
      text-align: center;
      margin-top: 40px;
      font-weight: bold;
      font-family: $font-secondary4;
    }
    &-tab {
      width: 100%;
      flex: 1;
      border-bottom: 1px solid $color-link-water;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      img {
        margin-left: auto;
        margin-right: auto;
        width: 80px;
      }

      &.is-active {
        border-bottom: 2px solid $color-primary;
      }
    }
    &-tabs {
      width: 100%;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
