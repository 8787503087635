@use '/src/assets/new_variables.scss' as *;

.navbar-v2-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: $color-primary;
  justify-self: start;

  @media screen and (min-width: 992px) {
    padding: 20px;
  }
  &-tags {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    gap: 20px;
    list-style: none;
  }

  &-tag-btn {
    border: 0;
    border-radius: 5px;
    background-color: $color-link-water;
    color: $color-primary;
    font-size: 16px;
    font-family: $font-secondry;
    padding: 5px 10px;

    &.search-tag-selected {
      background-color: $color-primary;
      color: #ffffff;
    }
  }

  &-icon {
    border: 0;
    background-color: transparent;

    &.search-icon-right {
      right: 0;
    }
    &.search-icon-left {
      left: 0;
    }
  }
  &-input-group {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #adbcd2;
    position: relative;

    & input {
      flex: 1;
      border: none;
      outline: none;
      padding: 20px 0px;
      color: $color-primary;
      font-size: 26px;
      font-weight: 500;

      &::placeholder {
        color: $color-primary;
        font-family: $font-secondary4;
      }
    }
  }
  &-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    height: 5px;
    background-color: $color-primary;
    border-radius: 100px;
    &-bar {
      position: relative;
      height: 5px;
      background-color: $color-link-water;
      border-radius: 100px;
    }
  }
  &-result-container {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  &-results {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 30px 0;
    &-status {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      &-text {
        font-family: $font-secondry;
        font-size: 20px;
      }
      &-load {
        cursor: pointer;
        text-decoration: underline;
        font-size: 20px;
        font-family: $font-secondry;

        @media screen and (max-width: 1199px) {
          height: 100px;
        }
      }
    }
  }
  &-result-item {
    display: flex;
    flex-direction: column;
  }
  &-result-link,
  &-result-highlight,
  &-result-link:hover {
    color: $color-primary-dark;
    font-family: $font-secondry;
  }
  &-result-link {
    position: relative;
    &::before {
      content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $color-primary-dark; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      left: -1em; /* Also needed for space (tweak if needed) */
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }

    font-size: 22px;
  }
  &-result-highlight {
    font-size: 18px;
  }
  &-status {
    padding-top: 20px;
    font-family: $font-secondry;
    color: $color-gray-text;
    font-size: 18px;
  }
  &-total {
    text-transform: uppercase;
  }
  &-popular {
    margin-top: 40px;

    &-list {
      display: flex;
      list-style: none;
      padding: 0;
      gap: 15px;
      flex-wrap: wrap;
      margin: 0;
      margin-bottom: 15px;
    }
    &-title {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 10px;
      font-family: $font-secondary4;

      @media screen and (max-width: 1199px) {
        font-size: 20px;
      }
    }
    &-item,
    &-item:hover {
      color: $color-primary;
      font-size: 18px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
