@use '/src/assets/new_variables.scss' as *;

.i-need {
  width: 100%;
  .nav-tabs {
    border: none;
    justify-content: center;
    margin-top: 50px;
    height: 60px;

    @media screen and (max-width: 991px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: auto;
      overflow-y: hidden;
      margin: 1rem;
      height: 50px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .nav-item {
      display: flex;
      margin-left: -7px;
      &:first-child {
        margin-left: 0;
      }
      .nav-link {
        border: #dee2e6 solid 1px;
        border-radius: 0;
        border-right: unset;
        isolation: auto;
        color: $color-primary;
        white-space: nowrap;
        height: 100%;
        min-width: 248px;
        display: unset;

        &.active {
          background: $color-primary;
          color: #ffffff;
        }
        @media screen and (max-width: 991px) {
          min-width: auto;
        }
      }

      &:first-child {
        .nav-link {
          border-radius: 10px 0 0 10px;
        }
      }
      &:last-child {
        .nav-link {
          border-radius: 0 10px 10px 0;
          border: #dee2e6 solid 1px;
        }
      }

      .arrow-button {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 6px solid #dee2e6;
        align-self: center;
        z-index: 1;

        &.active {
          border-left: 6px solid $color-primary;
          margin-right: 1px;
        }
      }
    }
  }

  .tab-content {
    padding: 1rem 3rem;

    .tab-pane {
      //  TODO
      & .carousel {
        & .carousel-inner {
          display: flex;
          min-height: 110px;
          justify-content: center;
          &.vertical-mode {
            flex-direction: column;
          }
          & .carousel-item {
            flex: 0 0 calc(100% / 9);
            display: block;
            margin-right: 0;
            overflow: hidden;
            @media screen and (max-width: 991px) {
              border: 1px solid $color-link-water;
            }
            .icon-link {
              display: flex !important;
              flex-direction: column;
              align-items: center;
              text-decoration: none;
              color: $color-primary;

              @media screen and (max-width: 991px) {
                flex-direction: row;
              }

              .image-title {
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                color: $color-primary;
                font-family: 'Assistant';
              }

              img {
                width: 80px;
                height: 80px;
                transition: all 0.3s ease-in;
                &:hover {
                  transform: scale(1.1);
                  transition: all 0.3s ease-in;
                }
              }
            }
          }
        }
        & .carousel-control-prev,
        & .carousel-control-next {
          top: 36px;
          width: 40px;
          height: 40px;
          font-size: 0;
          line-height: 0;
          position: absolute;
          padding: 0;
          transform: translate(0, -50%);
          opacity: 1;
          &:before {
            color: $color-primary;
            font-size: 40px;
            font-family: 'slick';
            line-height: 1;
            opacity: 0.75;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }

        & .carousel-control-next {
          &:before {
            content: '→';
          }
          & .carousel-control-next-icon {
            background-image: none;
          }
        }
        & .carousel-control-prev {
          & .carousel-control-prev-icon {
            background-image: none;
          }
          &:before {
            content: '←';
          }
        }
      }

      .slick-slider {
        .slick-arrow {
          &.slick-prev,
          &.slick-next {
            top: 36px;
            width: 40px;
            height: 40px;
          }
          &::before {
            color: $color-primary;
            font-size: 40px;
          }
        }

        .slick-list {
          .slick-track {
            height: auto !important;
            .slick-slide {
              @media screen and (max-width: 991px) {
                border: 1px solid $color-link-water;
              }
              .icon-link {
                display: flex !important;
                flex-direction: column;
                align-items: center;
                text-decoration: none;
                color: $color-primary;

                @media screen and (max-width: 991px) {
                  flex-direction: row;
                }

                .image-title {
                  text-align: center;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 24px;
                  color: $color-primary;
                  font-family: 'Assistant';
                }

                img {
                  width: 80px;
                  height: 80px;
                  transition: all 0.3s ease-in;
                  &:hover {
                    transform: scale(1.1);
                    transition: all 0.3s ease-in;
                  }
                }
              }
            }
          }
        }
      }

      .carousel-container {
        .carousel-wrapper {
            .carousel-content {
              &.flex-center {
                justify-content: center;
              }
              .icon-link {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                align-items: center;
                color: $color-primary;
                text-decoration: none;
                max-width: 203px;
                img {
                  height: 80px;
                  width: 80px;
                  transition: all 0.3s ease-in;
                  &:hover {
                    transform: scale(1.1);
                    transition: all 0.3s ease-in;
                  }
                }
                .image-title {
                  color: $color-primary;
                  font-family: $font-secondry;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: center;
                }
              }
            }
            .right-arrow {
              right: -27px;
              @media screen and (max-width: 1400px) {
                right: -36px;
              }
            }
            .left-arrow {
              left: -28px;
              @media screen and (max-width: 1400px) {
                left: -36px;
              }
            }
        }
      }
    }
  }

  .contact-row {
    display: flex;
    justify-content: space-evenly;
    padding: 2rem 5rem;
    border-top: $color-link-water solid 1px;
    color: $color-primary;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    direction: ltr;

    @media screen and (max-width: 1199px) {
      flex-direction: column;
      padding: 0;
      padding-top: 2rem;
      border-top: none;
    }

    .zim-believes-text {
      font-weight: 500;
      flex: 0 0 40%;
      text-align: end;
      font-family: 'Assistant';

      @media screen and (max-width: 1199px) {
        flex: 0 0 100%;
        text-align: center;
      }
    }

    .icons-row {
      display: flex;
      flex: 0 0 60%;
      @media screen and (max-width: 1199px) {
        flex: 0 0 100%;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .contact-icon {
        cursor: pointer;
        padding-left: 100px;
        @media screen and (max-width: 1199px) {
          display: flex;
          flex-direction: column;
          padding-left: 0;
        }
        a {
          color: $color-primary;
          text-decoration: underline;
        }

        .iconImage {
          width: 36px;
          height: 30px;
          margin-right: 7px;

          @media screen and (max-width: 1199px) {
            align-self: center;
          }

          &.phone {
            width: 39px;
            height: 30px;
          }
        }
      }
    }
  }
}
