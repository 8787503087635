@use '/src/assets/new_variables.scss' as *;

.navbar-v2-dropblock {
  &-popup {
    position: absolute;
    top: 100%;
  }
  &-backdrop {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: #000000;
    z-index: 3000;
    &-open {
      &-enter {
        opacity: 0;
        transform: scale(0.9);
      }
      &-enter-active {
      }
      &-enter-done {
        opacity: 0.5;
      }

      &-exit {
        opacity: 0.5;
      }
      &-exit-active {
      }
      &-exit-done {
        opacity: 0;
      }
    }
  }

  &-block {
    position: block;
    width: 100%;
  }
  &-left {
    left: 0;
  }

  &-right {
    right: 0;
  }

  &-content {
    display: flex;
    z-index: 4000;
    right: 0;

    flex-direction: column;
    align-items: center;
    background-color: white;

    @media (min-width: 992px) {
      min-width: 300px;
      max-height: calc(100vh - 162px);
      overflow: auto;
    }
    &-open {
      &-enter {
        opacity: 0;
        transform: scale(0.9);
      }
      &-enter-active {
        opacity: 0;
        transform: scale(0.9);
      }
      &-enter-done {
      }

      &-exit {
        opacity: 1;
      }
      &-exit-active {
      }
    }

    &-ineed {
      @media (max-width: 991px) {
        max-height: calc(100vh - 100px);
        overflow: auto;
      }
    }
  }
}
